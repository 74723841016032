<template>
  <div @wheel="mouseWheel">

    <!-- Top Section -->
    <div ref="top" class="section top">
      <div class="top__container">
        <div class="top__info">
          <div class="top__title">
            <p>Retail Onboarding</p>
          </div>
          <div class="top__subtitle">
            <p>Tailored Retail? Geddit!</p>
          </div>
          <div class="top__line">
            <svg viewBox="0 0 149 13">
              <g transform="translate(1 1)" fill="#00558d">
                <path id="green-scales-a"
                      d="M22 0a1 1 0 1 0-2 0h2zM1 0a1 1 0 1 0-2 0h2zm19 0c0 5.567-4.296 10-9.5 10v2C16.895 12 22 6.583 22 0h-2zm-9.5 10C5.296 10 1 5.567 1 0h-2c0 6.583 5.105 12 11.5 12v-2z"></path>
              </g>
              <g transform="translate(22 1)" fill="#00558d">
                <path id="green-scales-a"
                      d="M22 0a1 1 0 1 0-2 0h2zM1 0a1 1 0 1 0-2 0h2zm19 0c0 5.567-4.296 10-9.5 10v2C16.895 12 22 6.583 22 0h-2zm-9.5 10C5.296 10 1 5.567 1 0h-2c0 6.583 5.105 12 11.5 12v-2z"></path>
              </g>
              <g transform="translate(43 1)" fill="#00558d">
                <path id="green-scales-a"
                      d="M22 0a1 1 0 1 0-2 0h2zM1 0a1 1 0 1 0-2 0h2zm19 0c0 5.567-4.296 10-9.5 10v2C16.895 12 22 6.583 22 0h-2zm-9.5 10C5.296 10 1 5.567 1 0h-2c0 6.583 5.105 12 11.5 12v-2z"></path>
              </g>
              <g transform="translate(64 1)" fill="#00558d">
                <path id="green-scales-b"
                      d="M22 0a1 1 0 1 0-2 0h2zM1 0a1 1 0 1 0-2 0h2zm19 0c0 5.567-4.297 10-9.5 10v2C16.894 12 22 6.583 22 0h-2zm-9.5 10C5.297 10 1 5.567 1 0h-2c0 6.583 5.106 12 11.5 12v-2z"></path>
              </g>
              <g transform="translate(85 1)" fill="#00558d">
                <path id="green-scales-b"
                      d="M22 0a1 1 0 1 0-2 0h2zM1 0a1 1 0 1 0-2 0h2zm19 0c0 5.567-4.297 10-9.5 10v2C16.894 12 22 6.583 22 0h-2zm-9.5 10C5.297 10 1 5.567 1 0h-2c0 6.583 5.106 12 11.5 12v-2z"></path>
              </g>
              <g transform="translate(106 1)" fill="#00558d">
                <path id="green-scales-b"
                      d="M22 0a1 1 0 1 0-2 0h2zM1 0a1 1 0 1 0-2 0h2zm19 0c0 5.567-4.297 10-9.5 10v2C16.894 12 22 6.583 22 0h-2zm-9.5 10C5.297 10 1 5.567 1 0h-2c0 6.583 5.106 12 11.5 12v-2z"></path>
              </g>
              <g transform="translate(127 1)" fill="#00558d">
                <path id="green-scales-b"
                      d="M22 0a1 1 0 1 0-2 0h2zM1 0a1 1 0 1 0-2 0h2zm19 0c0 5.567-4.297 10-9.5 10v2C16.894 12 22 6.583 22 0h-2zm-9.5 10C5.297 10 1 5.567 1 0h-2c0 6.583 5.106 12 11.5 12v-2z"></path>
              </g>
            </svg>
          </div>
          <div class="top__description">
            <p>Congratulations. It appears you’ve decided to switch to Shopify POS. We also think this is the best
              POS on the market. Clement is here to help you from the day you sign-up until all of your locations are
              live and ringing up transactions.</p>
          </div>
          <!--          <a href="#info" v-smooth-scroll>Info</a>-->
          <a href="#" @click.prevent="scrollTo"></a>
        </div>
        <div class="top__image">
          <img src="@/assets/top/marketing-anim.gif" alt="animation">
        </div>
      </div>
    </div>

    <!-- Info Section -->
    <div ref="info" class="section info">
      <div class="info__container info__container--animation">
        <div v-for="(block, index) in retailStore.info"
             class="info__line" :key="index">
          <div class="info__block">
            <div class="info__block-text">
              <div class="info__smtitle">
                <p>{{block.smtitle}}</p>
              </div>
              <div class="info__title">
                <p>{{block.title}}</p>
              </div>
              <div class="info__text">
                <p>{{block.text}}</p>
              </div>
            </div>
          </div>
          <div class="info__image">
            <img :src="block.image" :alt="block.smtitle">
          </div>
        </div>
      </div>
    </div>

    <!-- Repeat Info Section -->
    <div class="section info">
      <div class="info__container info__container--animation2">
        <div v-for="(block, index) in retailStore.info2"
             class="info__line" :key="index">
          <div class="info__block">
            <div class="info__block-text">
              <div class="info__smtitle">
                <p>{{block.smtitle}}</p>
              </div>
              <div class="info__title">
                <p>{{block.title}}</p>
              </div>
              <div class="info__text">
                <p>{{block.text}}</p>
              </div>
            </div>
          </div>
          <div class="info__image">
            <img :src="block.image" :alt="block.smtitle">
          </div>
        </div>
      </div>
    </div>

    <!-- Portfolio Section -->
    <div class="section portfolio">
      <div class="portfolio__container">
        <div class="portfolio__line portfolio__line--once">
          <div class="portfolio__block" @click="$emit('changeRoute', {name: 'ServiceShopifyRetailOnboarding'})"
               :style="{backgroundImage: 'url(' + require('@/assets/services/grid/data-migration-grid-tile.jpg') + ')'}" >
            <div class="portfolio__info">
              <div class="portfolio__info-title">
                <p>Data Migration</p>
              </div>
              <div class="portfolio__info-subtitle">
                <p>Move IT. Move your Data!</p>
              </div>
              <div class="portfolio__arrow">
                <svg id="icon-long-arrow" viewBox="0 0 51 15"><title>Arrow Right</title>
                  <desc>Data Migration</desc>
                  <g transform="rotate(-90 29.23 -13.17)">
                    <path id="long-arrow-a" fill-rule="evenodd"
                          d="M2.394 0c-.888 0-1.335 1.071-.711 1.703L7.838 7.93a1 1 0 0 0 1.423 0l6.155-6.228c.625-.632.177-1.703-.71-1.703H2.393z"></path>
                  </g>
                  <g transform="translate(2 6)">
                    <path id="long-arrow-b"
                          d="M41.85 3a1.5 1.5 0 0 0 0-3v3zM0 0a1.5 1.5 0 0 0 0 3V0zm41.85 0H0v3h41.85V0z"></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="portfolio__line portfolio__line--double">
          <div class="portfolio__block" @click="$emit('changeRoute', {name: 'ServiceShopifyOnlineServices'})"
               :style="{backgroundImage: 'url(' + require('@/assets/services/grid/web-grid-tile.jpg') + ')'}">
            <div class="portfolio__info">
              <div class="portfolio__info-title">
                <p>Stand Out like Clement's Steed</p>
              </div>
              <div class="portfolio__info-subtitle">
                <p>Web Design Services</p>
              </div>
              <div class="portfolio__arrow">
                <svg id="icon-long-arrow" viewBox="0 0 51 15"><title>Arrow Right</title>
                  <desc>Web Design Services</desc>
                  <g transform="rotate(-90 29.23 -13.17)">
                    <path id="long-arrow-a" fill-rule="evenodd"
                          d="M2.394 0c-.888 0-1.335 1.071-.711 1.703L7.838 7.93a1 1 0 0 0 1.423 0l6.155-6.228c.625-.632.177-1.703-.71-1.703H2.393z"></path>
                  </g>
                  <g transform="translate(2 6)">
                    <path id="long-arrow-b"
                          d="M41.85 3a1.5 1.5 0 0 0 0-3v3zM0 0a1.5 1.5 0 0 0 0 3V0zm41.85 0H0v3h41.85V0z"></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div class="portfolio__block" @click="$emit('changeRoute', {name: 'ServiceAppDevelopment'})"
               :style="{backgroundImage: 'url(' + require('@/assets/services/grid/app-services-grid-tile.jpg') + ')'}">
            <div class="portfolio__info">
              <div class="portfolio__info-title">
                <p>Apps Ap-plenty</p>
              </div>
              <div class="portfolio__info-subtitle">
                <p>Mobile App Designs & Development</p>
              </div>
              <div class="portfolio__arrow">
                <svg id="icon-long-arrow" viewBox="0 0 51 15"><title>Arrow Right</title>
                  <desc>Mobile Apps</desc>
                  <g transform="rotate(-90 29.23 -13.17)">
                    <path id="long-arrow-a" fill-rule="evenodd"
                          d="M2.394 0c-.888 0-1.335 1.071-.711 1.703L7.838 7.93a1 1 0 0 0 1.423 0l6.155-6.228c.625-.632.177-1.703-.71-1.703H2.393z"></path>
                  </g>
                  <g transform="translate(2 6)">
                    <path id="long-arrow-b"
                          d="M41.85 3a1.5 1.5 0 0 0 0-3v3zM0 0a1.5 1.5 0 0 0 0 3V0zm41.85 0H0v3h41.85V0z"></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>

        </div>
        <div class="portfolio__line portfolio__line--once">
          <div class="portfolio__block" @click="$emit('changeRoute', {name: 'ServiceMarketing'})"
               :style="{backgroundImage: 'url(' + require('@/assets/services/grid/marketing-grid-tile.jpg') + ')'}">
            <div class="portfolio__info">
              <div class="portfolio__info-title">
                <p>Where are you?</p>
              </div>
              <div class="portfolio__info-subtitle">
                <p>Marketing, Ads, SEO. All the things!</p>
              </div>
              <div class="portfolio__arrow">
                <svg id="icon-long-arrow" viewBox="0 0 51 15"><title>Arrow Right</title>
                  <desc>Back to Services</desc>
                  <g transform="rotate(-90 29.23 -13.17)">
                    <path id="long-arrow-a" fill-rule="evenodd"
                          d="M2.394 0c-.888 0-1.335 1.071-.711 1.703L7.838 7.93a1 1 0 0 0 1.423 0l6.155-6.228c.625-.632.177-1.703-.71-1.703H2.393z"></path>
                  </g>
                  <g transform="translate(2 6)">
                    <path id="long-arrow-b"
                          d="M41.85 3a1.5 1.5 0 0 0 0-3v3zM0 0a1.5 1.5 0 0 0 0 3V0zm41.85 0H0v3h41.85V0z"></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Contact Section-->
    <div class="section contact">
      <div class="contact__container">
        <!-- <router-link to="/services" class="contact__link">-->
        <div class="contact__link" @click="$emit('changeRoute', {name: 'services'})" :class="{fadeInDown: ($store.state.section == 3), fadeOutDown: ($store.state.section != 3)}">
          <div class="contact__arrow contact__arrow--back">
            <svg id="icon-long-arrow" viewBox="0 0 51 15"><title>Arrow Right</title>
              <desc>Created using Figma</desc>
              <g transform="rotate(-90 29.23 -13.17)">
                <path id="long-arrow-a" fill-rule="evenodd"
                      d="M2.394 0c-.888 0-1.335 1.071-.711 1.703L7.838 7.93a1 1 0 0 0 1.423 0l6.155-6.228c.625-.632.177-1.703-.71-1.703H2.393z"></path>
              </g>
              <g transform="translate(2 6)">
                <path id="long-arrow-b"
                      d="M41.85 3a1.5 1.5 0 0 0 0-3v3zM0 0a1.5 1.5 0 0 0 0 3V0zm41.85 0H0v3h41.85V0z"></path>
              </g>
            </svg>
          </div>
          <div class="contact__title">
            <p>Back</p>
          </div>
        </div>
        <!--</router-link>-->
        <a href="#" @click.prevent="$store.state.modalOpened = true" class="contact__link">
          <div class="contact__title">
            <p>Get in Touch</p>
          </div>
          <div class="contact__arrow contact__arrow--next">
            <svg id="icon-long-arrow" viewBox="0 0 51 15"><title>Arrow Right</title>
              <desc>Created using Figma</desc>
              <g transform="rotate(-90 29.23 -13.17)">
                <path id="long-arrow-a" fill-rule="evenodd"
                      d="M2.394 0c-.888 0-1.335 1.071-.711 1.703L7.838 7.93a1 1 0 0 0 1.423 0l6.155-6.228c.625-.632.177-1.703-.71-1.703H2.393z"></path>
              </g>
              <g transform="translate(2 6)">
                <path id="long-arrow-b"
                      d="M41.85 3a1.5 1.5 0 0 0 0-3v3zM0 0a1.5 1.5 0 0 0 0 3V0zm41.85 0H0v3h41.85V0z"></path>
              </g>
            </svg>
          </div>
        </a>

      </div>
    </div>

    <Footer class="footerdm"></Footer>
  </div>
</template>

<script>
  import {TimelineMax} from 'gsap';
  import InView from 'in-view';
  import {gsap} from "gsap"
  import Footer from '../components/footer';

  export default {

    data() {
      return {
        sectionState: 0,
        scrollAnimation: false,
        infoAnimation: null,
        infoAnimation2: null,
      }
    },

    components: {
      Footer
    },

    metaInfo: {
      title: 'Shopify Merchant Retail and POS On-boarding and Services',
      meta: [
        {
          //vmid: 'description',
          name: 'description',
          content:
                  'Migrate all your data from your current POS (Point of Sale) system to your new Shopify POS. We can migrate all your customer, order and product historical data to ensure a seamless and quick transition. We will work with you to create custom training materials and train your staff on Shopify POS.'
        }
      ]
    },
    watch: {
      section() {
        this.$store.state.serviceParticularFirstSlide = this.service == 0 ? true : false
      }
    },

    methods: {
      infoInView() {
        // console.log(1);
      },

      mouseWheel(e) {
        const {info, top} = this.$refs;
        // Check run animation status

        if (!this.scrollAnimation) {
          e = e || window.event;
          const delta = e.deltaY || e.detail || e.wheelDelta;
          if (delta > 0) {
            // If Section Top in viewport run scrollTo animation
            if (this.sectionState === 0 && this.elementInViewport(top)) {
              // Change animation status
              this.scrollAnimation = true;
              // Scroll to info block 1.5 second
              this.$scrollTo(info, 1500, {
                onDone: () => {
                  this.scrollAnimation = false;
                  // this.sectionState = 1;
                  //console.log('done'); Nik
                }
              });
            }
          } else {
            // console.log('up');
          }
          return false;
        }

      },

      // Check element in viewport
      elementInViewport(el) {
        let rect = el.getBoundingClientRect();
        let elemTop = rect.top;
        let elemBottom = rect.bottom;

        return (elemTop >= 0) && (elemBottom <= window.innerHeight);

      },

    },

    computed: {
      retailStore() {
        return this.$store.state.retailOnboarding
      },

      section() {
        return this.$store.state.section
      },
    },

    mounted() {
      // Add class to BODY for controlling overflow and height
      document.body.classList.add('body-data');
      window.scrollTo(0, 0);

      // if is Mobile we not add timeline animation for blocks images
      if (!this.$root.isMobile) {
        let images = document.querySelectorAll('.info__image img');

        this.infoAnimation = new TimelineMax({paused:true});
        this.infoAnimation
                .set([images[0], images[1], images[2]], {autoAlpha: 0});
        this.infoAnimation
                .from(images[0], .8, {autoAlpha: 0, y: -500})
                .from(images[1], .8, {autoAlpha: 0, y: 500}, "-=.8")
                .from(images[2], .8, {autoAlpha: 0, y: -500}, "-=.8");

        // this.infoAnimation.stop();

        let images2 = document.querySelectorAll('.info__container--animation2 img');
        this.infoAnimation2 = new TimelineMax({paused:true});
        this.infoAnimation2
                .set([images2[0], images2[1], images2[2]], {autoAlpha: 0});
        this.infoAnimation2
                .from(images2[0], .8, {autoAlpha: 0, y: -500})
                .from(images2[1], .8, {autoAlpha: 0, y: 500}, "-=.8")
                .from(images2[2], .8, {autoAlpha: 0, y: -500}, "-=.8");
        // this.infoAnimation2.stop();


        InView.offset(window.innerHeight / 2);
        InView('.info__container--animation')
                .once('enter', () => {
                  //console.log(this);
                  this.infoAnimation.play();
                });
        InView('.info__container--animation2')
                .once('enter', () => {
                  this.infoAnimation2.play();
                });
      }


    },

    beforeDestroy() {
      // Remove BODY class for controlling overflow and height
      document.body.classList.remove('body-data');
    }
  }
</script>

<style lang="sass" scoped>

  // Variables
  $infoBackground: #15a9de

  // Media
  @mixin mobile()
    @media (min-width: 320px) and (max-width: 480px)
      @content

  @mixin mobile-landscape()
    @media (min-width: 481px) and (max-width: 768px)
      @content

  @mixin tablet()
    @media (min-width: 768px) and (max-width: 1024px)
      @content

  @mixin tablet-landscape()
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)
      @content

  @mixin laptop()
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)
      @content

  // Styles
  .btn
    &:hover
      span
        color: #333 !important

  .footerdm
    background-color: $infoBackground !important

    .ftop
      .btn
        &:hover
          span
            color: #333 !important

  .top
    background: $infoBackground
    height: 100vh
    //font-family: neue-kabel, sans-serif

    p
      margin: 0

    &__container
      display: flex
      max-width: 1200px
      width: 100%
      margin: 0 auto
      height: 100%
      align-items: center
      justify-content: space-between
      padding: 25px
      box-sizing: border-box

    &__info
      width: 400px
      text-align: left

    &__image
      width: 800px

      img
        width: 100%

    &__title

      p
        font-size: 60px
        font-weight: 700

    &__subtitle
      margin-top: 15px

      p
        font-size: 26px
        font-weight: 500

    &__line
      margin: 25px 0

      svg
        width: 150px

    &__description

      p
        //font-family: goudy-old-style, serif
        font-size: 1.4rem
        line-height: 1.2
        margin: 0

  .info
    background: $infoBackground
    height: 100vh
    //font-family: neue-kabel, sans-serif

    &__container
      display: flex
      max-width: 1440px
      width: 100%
      margin: 0 auto
      height: 100%
      align-items: center
      padding: 25px
      box-sizing: border-box

    &__line
      display: flex
      flex-flow: column wrap
      width: calc(100% / 3)

      &:nth-child(2)
        .info__image
          order: 1

        .info__block
          order: 2

    &__block
      min-height: 320px
      display: flex
      justify-content: center

    &__block-text
      max-width: 350px
      width: 100%
      padding: 25px
      display: flex
      flex-flow: column wrap
      margin: auto
      box-sizing: border-box

    &__smtitle
      margin-bottom: 15px

      p
        font-weight: bold
        text-transform: uppercase
        font-size: 16px
        margin: 0

    &__title

      p
        margin: 0
        font-size: 40px

    &__text
      margin-top: 20px

      p
        //font-family: goudy-old-style, serif
        font-size: 18px
        margin: 0

    &__image
      max-width: 400px
      min-height: 320px
      width: 100%
      display: flex
      justify-content: center
      align-items: center
      margin: 0 auto
      padding: 25px
      box-sizing: border-box
      overflow: hidden

      img
        width: 100%

  .portfolio
    height: 700px
    width: 100%
    background: #000

    p
      margin: 0

    &__container
      display: flex
      height: 100%

    &__line
      width: 33.333%
      height: 100%
      display: flex
      flex-flow: column wrap

      &--once
        .portfolio__block
          height: 100%

      &--double
        .portfolio__block
          height: 50%

    &__block
      background-size: cover
      background-position: center center
      background-repeat: no-repeat
      display: flex
      align-items: center
      justify-content: center
      height: 100%
      position: relative

      &:hover
        &:after
          opacity: 1

        .portfolio__info
          opacity: 1
          transform: translateY(0)

      &:after
        content: ''
        position: absolute
        width: 100%
        height: 100%
        left: 0
        top: 0
        background: $infoBackground
        opacity: 0
        z-index: 1
        transition: .2s ease-in-out opacity

    &__info
      position: relative
      opacity: 0
      z-index: 2
      transform: translateY(25px)
      transition: .35s ease-in-out transform, .35s ease-in-out opacity

    &__info-title

      p
        //font-family: neue-kabel, sans-serif
        font-size: 26px

    &__info-subtitle

      p
        //font-family: goudy-old-style, serif
        font-size: 18px

    &__arrow
      max-width: 40px
      margin: 25px auto
      position: relative
      left: -5px
      width: 100%
      animation: arrowMoveNext .75s linear alternate-reverse infinite

      svg path
        fill: #fff

  .contact
    height: 300px
    background: #000

    &__container
      display: flex
      align-items: center
      justify-content: center
      height: 100%

    &__link
      display: flex
      flex-flow: row nowrap
      align-items: center
      margin: 0 60px

    &__arrow
      width: 100px
      transition: .25s ease-in-out transform

      &--next
        animation: arrowMoveNext .75s linear alternate-reverse infinite

      &--back
        transform: rotate(180deg)
        animation: arrowMoveBack .75s linear alternate-reverse infinite

      svg

        path
          fill: #f9b233

    &__title
      margin: 0 50px

      p
        //font-family: neue-kabel, sans-serif
        font-size: 50px
        font-weight: 900
        white-space: nowrap

  @keyframes arrowMoveBack
    from
      transform: rotate(180deg)
    to
      transform: rotate(180deg) translateX(20px)

  @keyframes arrowMoveNext
    from
      transform: translateX(0)
    to
      transform: translateX(20px)

  @include laptop
    .info

      &__image
        height: auto
        min-height: auto

      &__block
        min-height: auto

      &__block-text
        margin: 0
        padding: 0
        height: auto

      &__smtitle
        p
          font-size: 14px

      &__title
        p
          font-size: 24px

      &__text
        padding: 10px

        p
          font-size: 16px

    .contact

      &__title
        margin: 0 10px

      &__link
        margin: 0 10px


  @include mobile-landscape
    .info

      &__image
        height: auto
        min-height: auto

      &__block
        min-height: auto

      &__block-text
        margin: 0
        padding: 0
        height: auto

      &__smtitle
        p
          font-size: 14px

      &__title
        p
          font-size: 24px

      &__text
        padding: 10px

        p
          font-size: 16px

    .top
      height: auto

      &__container
        flex-flow: row wrap

      &__info
        width: 100%
        order: 2

      &__image
        width: 100%
        order: 1

    .portfolio
      height: 300px

    .contact
      padding: 25px 0
      height: auto

      &__title
        margin: 0

        p
          font-size: 20px

      &__arrow
        width: 50px

      &__link
        margin: 0 10px

  @include mobile

    .info
      height: auto

      &__image
        height: auto
        min-height: auto

      &__block
        min-height: auto

      &__block-text
        margin: 0
        padding: 0
        height: auto

      &__container
        flex-flow: row wrap

      &__smtitle
        p
          font-size: 14px

      &__title
        p
          font-size: 24px

      &__text
        padding: 10px

        p
          font-size: 16px

      &__line
        width: 100%

        &:nth-child(2n-1)
          .info__image
            order: 1

          .info__block
            order: 2

    .top
      height: auto

      &__container
        flex-flow: row wrap

      &__info
        width: 100%
        order: 2

      &__image
        width: 100%
        order: 1

    .portfolio
      height: auto

      &__container
        flex-flow: column wrap

      &__line
        width: 100%

        &--once
          .portfolio__block
            height: 400px

        &--double
          .portfolio__block
            height: 200px

      &__block
        height: 100px

    .contact
      padding: 25px 0
      height: auto

      &__title
        margin: 0 5px

        p
          font-size: 20px

      &__arrow
        width: 25px
        animation: none !important
        height: 25px
        display: flex
        justify-content: center
        align-items: center

        svg
          width: 100%

      &__container
        flex-flow:  column wrap

      &__link
        width: 100%
        margin: 0 10px
        display: flex
        justify-content: center
        align-items: center
        margin: 0

        &:nth-child(1)
          order: 2

        &:nth-child(2)
          order: 1

</style>


<!--
<template>
  <div>
    <div class="navbarServices" :style="{backgroundColor: $store.state.services[1].backgroundColor}"></div>
    <transition name="serviceFadeUp">
      <div v-show="section === 0" class="serviceParticularS1" :style="{backgroundColor: $store.state.services[1].backgroundColor}">
        <img src="https://res.cloudinary.com/dsnrfqt9s/image/upload/q_auto/v1575327443/clementlabs-web/retail_shl7zv.png" alt="Shopify Retail POS Open Icon" class="serviceIcon">
        <h1>{{retailOnboarding.title}}</h1>
        <img src="https://ik.imagekit.io/nnol5se8mol/tr:h-50,w-50/downbtn_LWLlPOQ9nq.png" class="downBtn" @click="$store.state.section = 1">
      </div>
    </transition>
    <div class="container serviceParticular" :style="{backgroundColor: $store.state.services[1].backgroundColor}">
      <div class="section"></div>
      <div class="section serviceParticularS2">
        <h1>{{retailOnboarding.title1}}</h1>
        <div class="animated delay-1s" :class="{fadeInUp: ($store.state.section == 1), fadeOutDown: ($store.state.section != 1)}">
          <span v-for="subtitle in retailOnboarding.subtitles" :key="subtitle">{{subtitle}}</span>
        </div>
      </div>
      <div class="mgFeatures section">
        <h1>{{retailOnboarding.title2}}</h1>
        <div class="mgFeaturesContent makeColumns animated delay-1s" :class="{fadeInUp: ($store.state.section == 2), fadeOutDown: ($store.state.section != 2)}">
          <div v-for="migrationFeature in retailOnboarding.features" :key="migrationFeature.title">
            <span><strong>{{migrationFeature.title}}</strong></span><br>
            <small>{{migrationFeature.content}}</small>
          </div>
        </div>
      </div>
      <div class="section lastServicePartSection">
        <div class="tellusaboutyourprojectbtn">
          <div class="btn animated delay-1s" @click="$store.state.modalOpened = true" :class="{fadeInDown: ($store.state.section == 3), fadeOutDown: ($store.state.section != 3)}">
            <div></div>
            <span>> {{$store.state.service.contactBtn}} ></span>
          </div>
          <p>{{$store.state.service.contactPar}}</p>
        </div>
          <div class="btn animated delay-1s" style="position: relative; top: 100px" @click="$emit('changeRoute', {name: 'services'})" :class="{fadeInDown: ($store.state.section == 3), fadeOutDown: ($store.state.section != 3)}">
            <div></div>
            <span>&lt; Back to services &lt;</span>
          </div>
        <footerComp class="footerServiceParticular footeronboarding"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //VueMeta
  metaInfo: {
    title: 'Shopify Merchant Retail and POS On-boarding and Services',
    meta: [
      {
        //vmid: 'description',
        name: 'description',
        content:
                'Migrate all your data from your current POS (Point of Sale) system to your new Shopify POS. We can migrate all your customer, order and product historical data to ensure a seamless and quick transition. We will work with you to create custom training materials and train your staff on Shopify POS.'
      }
    ]
  },
  computed: {
    retailOnboarding() {
      return this.$store.state.retailOnboarding
    },
    
    section() {
      return this.$store.state.section
    },
  },
}
</script>

<style lang="sass" scoped>
.btn
  &:hover
    span
      color: #333 !important
</style>

<style lang="sass">
.footeronboarding
  background-color: transparent !important
  .ftop
    .btn
      &:hover
        span
          color: #333 !important
</style>-->
